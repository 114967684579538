import { ComponentType, PropsWithChildren, useCallback, useEffect } from 'react';

import { ModalContentProps } from '@zola/zola-ui/src/components/Modal/ModalContent/ModalContent';
import ModalV2 from '@zola/zola-ui/src/components/Modal/ModalV2';

import cx from 'classnames';
import Modal from 'react-modal';

import { ModalV2Props, PredefinedModalV2, useModal } from '~/contexts/ModalContext';
import { loadable } from '~/util/routeUtils';

import './ModalRoot.less';

/**
 * V2 modal components
 */

const MarketplaceAuthModalV2 = loadable(
  () => import('./modals/MarketplaceAuthModalV2/MarketplaceAuthModalV2')
);

const ReorderModal = loadable(() => import('./modals/BookedVendorModalV2/ReorderModal'));

const UnsuppliedMarketModal = loadable(() => import('./modals/UnsuppliedMarketModal'));

const VendorForgotPasswordModal = loadable(() => import('./vendorAuth/VendorForgotPasswordModal'));

const VendorLoginModal = loadable(() => import('./vendorAuth/VendorLoginModal'));

const VendorSignupModal = loadable(() => import('./vendorAuth/VendorSignupModal'));

const VideoModal = loadable(() => import('./zolaUI/VideoModal/VideoModal'));

const BookedConfirmationModal = loadable(
  () => import('./modals/BookedConfirmationModal/BookedConfirmationModal')
);

const BudgetResolutionModal = loadable(
  () => import('./modals/BudgetResolutionModal/BudgetResolutionModal')
);

const CancelPlanModal = loadable(
  () => import('~/pages/vendors/Credits/components/PlanManagement/CancelPlanModal')
);

const CouplesCancelInquiryModal = loadable(
  () => import('~/pages/couples/inquiries/components/CouplesCancelInquiryModal')
);

const CreditsNeededModal = loadable(
  () => import('~/pages/vendors/Inquiries/modals/CreditsNeededModal/CreditsNeededModal')
);

const DeleteSearchConfirmationModal = loadable(
  () => import('~/pages/couples/explore/components/SavedSearches/DeleteSearchConfirmationModal')
);

const EditCategoryModal = loadable(() => import('./modals/EditCategoryModal/EditCategoryModal'));

const EditPaymentMethodModal = loadable(
  () =>
    import(
      '~/pages/vendors/Credits/components/PlanManagement/EditPaymentMethodModal/EditPaymentMethodModal'
    )
);

export const EmailAddOnModal = loadable(
  () => import('~/pages/vendors/Credits/components/EmailAddOnModal/EmailAddOnModal')
);

const FirstMoveExplanationModal = loadable(
  () =>
    import('~/pages/vendors/Inquiries/modals/FirstMoveExplanationModal/FirstMoveExplanationModal')
);

const FirstMoveOptInModal = loadable(
  () => import('./modals/InvitesFromVendorsOptIn/FirstMoveOptInModal')
);

const HellosOptInModal = loadable(
  () => import('~/pages/vendors/Inquiries/InboxV3/components/LeadsView/Modals/HellosOptInModal')
);

const HellosOptOutModal = loadable(
  () => import('~/pages/vendors/LeadPreferences/components/modals/HellosOptOutModal')
);
const HellosMessageModal = loadable(
  () => import('~/pages/vendors/Inquiries/InboxV3/components/LeadsView/Modals/HellosMessageModal')
);

const HowWePriceModal = loadable(
  () => import('~/components/common/modals/HowWePriceModal/HowWePriceModal')
);

const InfoModal = loadable(() => import('~/pages/vendors/OnboardV2/modals/InfoModal/InfoModal'));

const InquiryResolutionsModal = loadable(
  () => import('./modals/InquiryResolutionsModal/InquiryResolutionsModal')
);

const InquiryConfirmationModal = loadable(
  () => import('./modals/InquiryConfirmationModal/InquiryConfirmationModal')
);

const InquiryDetailModal = loadable(() => import('./modals/InquiryDetailModal/InquiryDetailModal'));

const InquiryModal = loadable(() => import('~/pages/couples/inquiry-flow/InquiryModal'));

const InviteVendorUserModal = loadable(
  () => import('~/pages/vendors/UserManagement/components/InviteUserModal/InviteUserModal')
);

const LearnMoreAboutCreditPlansModal = loadable(
  () =>
    import(
      '~/pages/vendors/Credits/SelectPlan/modals/LearnMoreAboutCreditPlansModal/LearnMoreAboutCreditPlansModal'
    )
);

const MarkBookedModal = loadable(() => import('./modals/MarkBookedModal/MarkBookedModal'));

const MassInquiriesConfirmationModal = loadable(
  () => import('./modals/MassInquiriesConfirmationModal/MassInquiriesConfirmationModal')
);

const MiniQuizModal = loadable(() => import('./modals/MiniQuizModal/MiniQuizModal'));

const MiniQuizPromptModal = loadable(
  () => import('./modals/MiniQuizPromptModal/MiniQuizPromptModal')
);

const PaperCrossSellModal = loadable(() => import('./modals/PaperCrossSell/PaperCrossSell'));

const PaperSampleCheckoutModal = loadable(
  () => import('./modals/PaperSampleCheckout/PaperSampleCheckout')
);

const PricingHelpModal = loadable(
  () => import('~/pages/vendors/OnboardV2/modals/PricingHelpModal/PricingHelpModal')
);

const ProcessingModal = loadable(() => import('./modals/ProcessingModal/ProcessingModal'));

const QuickCheckoutModal = loadable(
  () => import('~/pages/vendors/Credits/components/QuickCheckoutModal/QuickCheckoutModal')
);

const QuickResponderModal = loadable(
  () => import('~/pages/vendors/Inquiries/modals/QuickResponderModal/QuickResponderModal')
);

const ReportSpamModal = loadable(
  () => import('~/pages/vendors/Inquiries/modals/ReportInquiryAsSpamModal/ReportInquiryAsSpamModal')
);

const SavedSearchesModal = loadable(
  () => import('~/pages/couples/explore/components/SavedSearches/SavedSearchesModal')
);

const SaveNewSearchModal = loadable(
  () => import('~/pages/couples/explore/components/SavedSearches/SaveNewSearchModal')
);

const SearchCardQuickViewModal = loadable(
  () => import('./VendorQuickView/SearchCardQuickViewModal/SearchCardQuickViewModal')
);

const SelectVendorTypeModal = loadable(
  () => import('./modals/BookedVendorModalV2/components/SelectVendorType')
);

const SimpleInfoModal = loadable(
  () => import('~/components/common/modals/SimpleInfoModal/SimpleInfoModal')
);

const StorefrontDetailSiderail = loadable(
  () =>
    import(
      '~/pages/vendors/UserManagement/components/StorefrontDetailSiderail/StorefrontDetailSiderail'
    )
);
const TextNotificationsModal = loadable(
  () => import('./modals/TextNotificationsModal/TextNotificationsModal')
);

const UnbookVendorModalV2 = loadable(() => import('./modals/BookedVendorModalV2/UnbookVendorV2'));

const UnclaimedClaimListingModal = loadable(
  () => import('./modals/UnclaimedClaimListingModal/UnclaimedClaimListingModal')
);

const UnclaimedLearnMoreModal = loadable(
  () => import('./modals/UnclaimedLearnMoreModal/UnclaimedLearnMoreModal')
);

const VendorCancelInquiryModal = loadable(
  () => import('~/pages/vendors/Inquiries/modals/VendorCancelInquiryModal/VendorCancelInquiryModal')
);

const VendorDetailsModal = loadable(
  () => import('./modals/BookedVendorModalV2/components/VendorDetails')
);

const V2MODAL_COMPONENTS: Record<PredefinedModalV2, ComponentType<any>> = {
  BOOKED_VENDORS_CONFIRMATION_MODAL: BookedConfirmationModal,
  BUDGET_RESOLUTION_MODAL: BudgetResolutionModal,
  CANCEL_PLAN: CancelPlanModal,
  COUPLES_CANCEL_INQUIRY_MODAL: CouplesCancelInquiryModal,
  CREDITS_NEEDED_MODAL: CreditsNeededModal,
  DELETE_SEARCH_CONFIRMATION: DeleteSearchConfirmationModal,
  EDIT_CATEGORY_MODAL: EditCategoryModal,
  EDIT_PAYMENT_METHOD_MODAL: EditPaymentMethodModal,
  EMAIL_ADD_ON_MODAL: EmailAddOnModal,
  FIRST_MOVE_EXPLANATION: FirstMoveExplanationModal,
  FIRST_MOVE_OPT_IN_MODAL: FirstMoveOptInModal,
  HELLOS_OPT_IN_MODAL: HellosOptInModal,
  HELLOS_OPT_OUT_MODAL: HellosOptOutModal,
  HELLOS_MESSAGE_MODAL: HellosMessageModal,
  HOW_WE_PRICE_MODAL: HowWePriceModal,
  INFO_MODAL: InfoModal,
  INQUIRY_CONFIRMATION_MODAL: InquiryConfirmationModal,
  INQUIRY_DETAIL_MODAL: InquiryDetailModal,
  INQUIRY_MODAL: InquiryModal,
  INQUIRY_RESOLUTIONS_MODAL: InquiryResolutionsModal,
  INVITE_VENDOR_USER_MODAL: InviteVendorUserModal,
  LEARN_MORE_ABOUT_CREDIT_PLANS: LearnMoreAboutCreditPlansModal,
  MARKETPLACE_AUTHV2: MarketplaceAuthModalV2,
  MARK_BOOKED_MODAL: MarkBookedModal,
  MASS_INQUIRY_CONFIRMATION_MODAL: MassInquiriesConfirmationModal,
  MINI_QUIZ_MODAL: MiniQuizModal,
  MINI_QUIZ_PROMPT_MODAL: MiniQuizPromptModal,
  PAPER_CROSS_SELL_PREVIEW: PaperCrossSellModal,
  PAPER_SAMPLE_CHECKOUT: PaperSampleCheckoutModal,
  PRICING_HELP_MODAL: PricingHelpModal,
  PROCESSING_MODAL: ProcessingModal,
  QUICK_CHECKOUT_MODAL: QuickCheckoutModal,
  QUICK_RESPONDER_MODAL: QuickResponderModal,
  REORDER_MODAL: ReorderModal,
  REPORT_SPAM_MODAL: ReportSpamModal,
  SAVED_SEARCHES: SavedSearchesModal,
  SAVE_NEW_SEARCH: SaveNewSearchModal,
  SEARCH_CARD_QUICK_VIEW: SearchCardQuickViewModal,
  SELECT_VENDOR_TYPE: SelectVendorTypeModal,
  STOREFRONT_USER_ACCESS_LIST_MODAL: StorefrontDetailSiderail,
  SIMPLE_INFO_MODAL: SimpleInfoModal,
  TEXT_NOTIFICATIONS: TextNotificationsModal,
  UNBOOK_VENDOR_V2: UnbookVendorModalV2,
  UNCLAIMED_CLAIM_LISTING: UnclaimedClaimListingModal,
  UNCLAIMED_LEARN_MORE: UnclaimedLearnMoreModal,
  UNSUPPLIED_MARKET: UnsuppliedMarketModal,
  VENDOR_CANCEL_INQUIRY_MODAL: VendorCancelInquiryModal,
  VENDOR_DETAILS: VendorDetailsModal,
  VENDOR_FORGOT_PASSWORD: VendorForgotPasswordModal,
  VENDOR_LOGIN: VendorLoginModal,
  VENDOR_SIGNUP: VendorSignupModal,
  VIDEO_MODAL: VideoModal,
};

const customStyles = {
  overlay: {
    backgroundColor: 'rgb(0, 0, 0, 0.5)',
    zIndex: 500,
  },
  content: {
    zIndex: 510,
  },
};

const ModalRoot = (): JSX.Element | null => {
  const { modalState, hideModal } = useModal();
  const {
    modalType,
    modalProps,
    modalOptions: {
      closeOnEsc = true,
      closeOnOverlayClick = true,
      onAfterOpen = undefined,
      onAfterClose = undefined,
      mobileHalfSheet = false,
      isMarketplaceAuthModal = false,
    } = {},
    modalV2,
    modalOptions,
    isVisible,
    modalContent,
    version,
  } = modalState;
  const { className } = modalOptions || {};

  const onEscape = useCallback(
    ({ key }: KeyboardEvent) => {
      if (key === 'Escape' && closeOnEsc && !modalV2) hideModal();
    },
    [closeOnEsc, hideModal, modalV2]
  );

  const onShow = useCallback(() => {
    if (document && document.body) {
      const orig = document.body.className;
      document.body.className = `${orig} modal-open`.trim();
    }
    document.addEventListener('keydown', onEscape);
    window.addEventListener('popstate', hideModal);
  }, [hideModal, onEscape]);

  const onHide = useCallback(() => {
    if (document && document.body) {
      document.body.className = document.body.className.replace(/ ?modal-open/g, '');
    }
    document.removeEventListener('keydown', onEscape);
    window.removeEventListener('popstate', hideModal);
  }, [hideModal, onEscape]);

  useEffect(() => {
    if (!modalType && !modalContent) {
      onHide();
    } else {
      onShow();
    }
  }, [modalContent, modalType, onHide, onShow]);

  let SpecificModal;

  if (modalType && version === 2) {
    SpecificModal = V2MODAL_COMPONENTS[modalType];
  } else {
    SpecificModal = modalContent;
  }
  const classes = cx(
    className || '',
    mobileHalfSheet ? `mobile-half-sheet` : '',
    isMarketplaceAuthModal ? ['close-button-tooltip', 'mobile-top-border'] : ''
  );

  if (!SpecificModal) {
    return null;
  }

  if (version !== 2) {
    return (
      <ModalContainer
        isOpen={isVisible}
        onRequestClose={hideModal}
        shouldCloseOnEsc={closeOnEsc}
        shouldCloseOnOverlayClick={closeOnOverlayClick}
        onAfterClose={onAfterClose}
        onAfterOpen={onAfterOpen}
        style={customStyles}
        className={classes}
        hideModalFn={hideModal}
        hideClose={modalProps?.hideClose}
      >
        <SpecificModal {...modalProps} hideModalFn={hideModal} />
      </ModalContainer>
    );
  }

  if (version === 2 && modalV2) {
    return <ModalV2Container hideModalFn={hideModal} modal={SpecificModal} {...modalV2} />;
  }

  return null;
};

const ModalV2Container = ({
  modal: SpecificModal,
  modalProps,
  contentProps,
  hideModalFn,
}: {
  modal: ((props: any) => JSX.Element) | ComponentType<any>;
  modalProps: ModalV2Props;
  contentProps: ModalContentProps;
  hideModalFn: () => void;
}) => {
  const { onAfterClose, ...zolaUiModalProps } = { onAfterClose: undefined, ...modalProps };

  const onClose = useCallback(() => {
    hideModalFn();

    if (onAfterClose) {
      onAfterClose();
    }
  }, [hideModalFn, onAfterClose]);

  return (
    <ModalV2 rootId="root" onClose={onClose} {...zolaUiModalProps}>
      <SpecificModal hideModalFn={onClose} {...contentProps} />
    </ModalV2>
  );
};

export const ModalContainer = ({
  children,
  className,
  hideModalFn,
  hideClose,
  size,
  ...props
}: PropsWithChildren<
  ReactModal.Props & { hideModalFn: () => void; hideClose: boolean; size?: string }
>) => (
  <Modal
    className={cx(
      'marketplace__modal-root-content',
      'modal-dialog',
      size ? `modal-${size}` : 'modal-lg',
      className
    )}
    style={customStyles}
    {...props}
  >
    {children}
    {!hideClose && (
      <button
        type="button"
        className="modal-close"
        onClick={hideModalFn}
        data-testid="modal-close"
        data-tip
        data-for="modal-close-button"
        data-place="right"
        data-effect="solid"
      >
        ×
      </button>
    )}
  </Modal>
);

export default ModalRoot;
